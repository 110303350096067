import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../../components/Layout'
// import BlogRoll from '../../components/BlogRoll'

const BlogPage = ({ pageContext: { locale }, ...props }) => {
  const { edges: posts } = props.data.blogPosts
    return (
      <Layout locale={locale}>
        <section className="news-page our-news">
          <div className="container">
            <div className="content">
              <div
                className="full-width-image-container margin-top-0"
                style={{
                  backgroundImage: `url('/img/top_img.jpg')`
                }}
              >
                <h1
                  className="has-text-weight-bold is-size-1"
                  style={{
                    color: "white",
                    padding: "1rem"
                  }}
                >
                  { locale === "en" ? ("Latest Stories") : ("Haberler") } 
                </h1>
              </div>
            </div>
            <div className="columns is-multiline">
              {posts.map(({ node: post }) => (
                  <div className="is-parent column" key={post.id}>
                    <article>
                      <div>
                        <Link className="title" to={post.fields.slug} title={post.frontmatter.title}>
                          {post.frontmatter.title}
                        </Link>
                        <span className="date">{post.frontmatter.date}</span>
                      </div>
                      <p>{post.excerpt}</p>
                      <Link className="button" to={post.fields.slug} title={post.frontmatter.title}>
                        { locale === "en" ? ("Keep Reading →") : ("Okumaya devam et →") } 
                        </Link>
                    </article>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </Layout>
    );
  }


export default BlogPage

BlogPage.propTypes = {
  posts: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  })
}



export const pageQuery = graphql`
query blogPageQuery($locale: String) {
  blogPosts: allMarkdownRemark(
    filter: {frontmatter: { pageKey: {eq: "page_blogpost"}, locale: { eq: $locale }}}
  ) {
    edges {
      node {
        fields {
          slug
        }
        excerpt(pruneLength: 200)
        id
        frontmatter {
          title
          pageKey
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
}
`
